<!--客户管理-我的设备-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
      <el-form :inline='true' :model='filter' size="mini">
        <el-form-item>
          <el-input v-model.trim='filter.name' placeholder='关键字搜索'>
            <el-button slot='prepend' icon='el-icon-refresh' @click='handleRefresh'></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' size='mini' @click='getData'>查询</el-button>
          <el-button type='primary' size='mini' @click='handleAdd'>新增</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span='24'>
      <el-table :data='datalist.content' size='mini' border highlight-current-row v-loading='loading' @selection-change='selsChange' :max-height='clientHeight' style='width: 100%'>
        <el-table-column type='selection'/>
        <el-table-column type='index' label='序号' align='center' width='55'/>
        <el-table-column prop='typeName' label='经营类型' align='center' width='200'  header-align='center'/>
        <el-table-column prop='name' label='经营类型名称' align='center' width='200'  header-align='center'/>
        <el-table-column prop='name' label='异常离线不判断时间' align='center'  header-align='center' show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.desc}}
          </template>
        </el-table-column>
        <el-table-column label='操作' align='center' header-align='center' fixed="right" width="140px">
          <template slot-scope='scope'>
            <i title='编辑' @click='handleEdit(scope.$index, scope.row)' class='opt el-icon-edit'/>
            <i title='删除' @click='handleDel(scope.$index, scope.row)' class='opt el-icon-delete'/>
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span='24' class='toolbar'>
      <el-button type='danger' size='mini' @click='bulkRemove' :disabled='sels.length===0'>批量删除</el-button>
      <el-pagination small background 
        @size-change='handleSizeChange' 
        @current-change='handleCurrentChange'
        :current-page.sync='filter.page' :page-sizes='filter.pageSizes'
        layout='total, sizes, prev, pager, next, jumper' :total='datalist.total'
        style='display:inline-block;margin-left:15px'
      >
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog :title='dlg.title' :visible.sync='dlg.visible' width="60%"  @close='handlerClose'>
      <el-form :model='formData' label-width='130px' :rules='formRule' ref='form' size='mini'>
        <el-form-item prop='type' label='经营类型'>
          <el-select
            placeholder="请选择经营类型"
            style="width: 100%"
            :disabled="dlg.state == 2"
            v-model="formData.type">
            <el-option :value="1" label="经营型" />
            <el-option :value="2" label="非经营型 "/>
          </el-select>
        </el-form-item>
        <el-form-item prop='name' label='经营类型名称'>
          <el-input  v-model.trim='formData.name'/>
        </el-form-item>
        <template v-if="setting.length > 0">
          <el-form-item label="异常离线判断时间配置">
            <el-checkbox-group v-model="setting_ids">
              <el-checkbox v-for="item in setting" :key="item.id" :label="item.id" @change="handleChange($event,item.id)">
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </template>
        <el-form-item v-if="showTime" label='时间区间选择'>
          <div>
            <el-date-picker
              v-model="timeSelect"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp">
            </el-date-picker>
            <el-button style="margin-left:20px;" type='primary' size='mini' @click='handleAddTime()'>添加</el-button>
          </div>
          <div v-if="times.length > 0" style="margin-top:20px;">
            <el-table :data='times' size='mini' border highlight-current-row style='width: 100%'>
              <el-table-column prop='start_time' label='开始时间' align='center'  header-align='center'>
                <template slot-scope="scope">
                  {{dateFormat(scope.row.start_time, false, false)}}
                </template>
              </el-table-column>
              <el-table-column prop='end_time' label='结束时间' align='center'  header-align='center'>
                <template slot-scope="scope">
                  {{dateFormat(scope.row.end_time, false, false)}}
                </template>
              </el-table-column>
              </el-table-column>
              <el-table-column label='操作' align='center' header-align='center'>
                <template slot-scope='scope'>
                  <i title='删除' @click='handleDelTime(scope.$index)' class='opt el-icon-delete'/>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
        <el-button type='primary' size='mini' @click='handleSubmit()'>提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {
  dateFormater

} from '@/util/index'
export default {
  name: 'Management',
  components: {},
  data() {
    return {
      changeState: false,
      sels: [], 
      loading: false,
      datalist: {},
      setting: [],
      dlg: {
        visible: false,
        state: null,
        title: ''
      },
      selDate: null,
      showTime: false,
      timeSelect: "",
      times: [],
      setting_ids: [],
      filter: {
        page: 1,
        size: 20,
        name: '',
        pageSizes: [20, 30, 50]
      },
      formData: {
        id: 0,
        type: 0,
        name: "",
        times: [],
        setting_ids: "",
      },
      formRule: {
        name: [{required: true, message: '监测点经营类型名称不可为空', trigger: 'blur'}],
        type: [{required: true, message: '监测点经营类型不可为空', trigger: 'blur'}],
      },
    }
  },
  computed: {
    ...mapState(['clientHeight']),
  },
  async created() {
    await this.getSetting()
    this.getData()
  },
  methods: {
    async getSetting() {
      this.setting = []
      this.$get('admin/management/setting', {}).then(res => {
        this.setting = res.list
      })
    },
    dateFormat(time) {
      return dateFormater(time, false,false)
    },
    handleChange(value, id) {
      if (id == 8) {
        this.showTime = value
      }
    },
    handleAddTime() {
      if(this.timeSelect && this.timeSelect.length == 2) {
        this.times.push({
          start_time: this.timeSelect[0] / 1000,
          end_time: this.timeSelect[1] / 1000
        })
      } else {
        this.$message({
          type: 'info',
          message: '请配置时间区间'
        })
        return
      }
    },
    getData () {
      this.loading = true
      const payload = {
        page: this.filter.page,
        size: this.filter.size,
        name: this.filter.name ? this.filter.name : ''
      }
      this.$get('admin/management', payload).then(res => {
        for (var i = 0; i < res.list.length; i++) {
          if (res.list[i].type == 1) {
            res.list[i].typeName = "经营类"
          } else {
            res.list[i].typeName = "非经营类"
          }
          var desc = ""
          for (var a = 0; a < this.setting.length; a++) {
            if (res.list[i].setting_ids.indexOf(this.setting[a].id) > -1) {
              desc = desc + this.setting[a].name 
              if (this.setting[a].id == 8 && res.list[i].time_settings) {
                desc = desc + "("
                for(var b = 0; b < res.list[i].time_settings.length; b++) {
                  desc = desc + this.dateFormat(res.list[i].time_settings[b].start_time, false, false) + "/" + this.dateFormat(res.list[i].time_settings[b].end_time, false, false) + " "
                }
                desc = desc + ")"
              }
              desc = desc + ";"
            }
          }
          res.list[i].desc = desc.slice(0, -1)
        }
        this.datalist.content = res.list
        this.datalist.total = res.count
        this.loading = false
      })
    },
    handleRefresh(clear = true) {
      if (clear) {
        this.filter.page = 1
        this.filter.name = ""
      }
      this.getData()
    },
    selsChange: function (sels) {
      this.sels = sels
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleAdd: function () {
      this.dlg.title = '新增'
      this.dlg.state = 1
      this.dlg.visible = true
      this.showTime = false
      this.times = []
      this.setting_ids = []
      this.formData = {type: 1}
    },
    handleEdit: function (index, row) {
      this.dlg.title = '编辑'
      this.dlg.state = 2
      this.dlg.visible = true
      this.$get(`admin/management/info/${row.id}`, {}).then(res => {
        this.formData = res
        this.setting_ids = res.setting_ids
        this.times = res.time_settings
        if (this.setting_ids.indexOf(8) == -1) {
          this.showTime = false
        } else {
          this.showTime = true
        }
      })
    },
    bulkRemove: function () {
      const ids = this.sels.map(item => item.id).toString()
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$post(`admin/management/delete/${ids}`, {}).then((res) => {
          this.getData()
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    handleDelTime(index){
      this.times.splice(index, 1)
    },
    handleDel: function (index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$post(`admin/management/delete/${row.id}`, {}).then(() => {
          this.datalist.content.splice(index, 1)
        }).catch(() => {
        })
      }).catch(() => {
      })
    },
    handleSubmit() {
      if (this.setting_ids.length == 0) {
        this.$message({
          type: 'info',
          message: '请配置异常离线时间'
        })
        return
      }
      if (this.showTime) {
        if (this.times.length == 0) {
          this.$message({
            type: 'info',
            message: '请自定义时间区间'
          })
          return
        }
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            const params = {
              name: this.formData.name,
              type: this.formData.type,
              setting_ids: this.setting_ids.join(","),
              times: JSON.stringify(this.times)
            }
            let param = new URLSearchParams()
              Object.entries(params).forEach(([key, val]) => {
                param.append(key, val)
              })

            if (this.dlg.state == 1) {
              this.$post('admin/management/add', param).then(res => {
                this.$message({
                  type: "success",
                  message: '添加成功'
                })
                this.dlg.visible = false
                setTimeout(()=> {
                  this.getData()
                }, 500)
              })
            } else {
              this.$post(`admin/management/edit/${this.formData.id}`, param).then(res => {
                this.$message({
                  type: "success",
                  message: '处理成功'
                })
                this.dlg.visible = false
                setTimeout(()=> {
                  this.getData()
                }, 500)
              })
            }
          }).catch((e) => {
            console.log(e)
          })
        }
      })
    },
    handlerClose: function () {
      this.formData = {}
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
